import React, { useState } from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSent, setShowSent] = useState(false);
    return (
        <Layout pageInfo={{ pageName: "index" }}>
            <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
            <div className="container">
                <div className="row justify-content-center pt-5">
                    <div className="a1">Contact Us</div>
                </div>
                <div className="row justify-content-center align-items-center pt-3 pb-3">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-block">
                                <form id="contact-form">
                                    <div className="form-group col-lg-12 pt-2">
                                        <label className="form-control-label" htmlFor="name">Name</label>
                                        <input type="text" className="form-control" id="name" onChange={e => setName(e.target.value)} required />
                                    </div>
                                    <div className="form-group col-lg-12">
                                        <label className="form-control-label" htmlFor="email">Email</label>
                                        <input type="email" className="form-control" id="email" onChange={e => setEmail(e.target.value)} required />
                                    </div>
                                    <div className="form-group col-lg-12">
                                        <label className="form-control-label" htmlFor="message">Message</label>
                                        <textarea className="form-control" id="message" name="notes" rows={6} onChange={e => setMessage(e.target.value)} required />
                                    </div>
                                    {showError ?
                                        <div className="form-group col-lg-12">
                                            <div className="alert alert-danger" role="alert">An error occurred</div>
                                        </div>
                                        : null}
                                    {showSent ?
                                        <div className="form-group col-lg-12">
                                            <div className="alert alert-success" role="alert">Message Sent</div>
                                        </div>
                                        : null}
                                    <div className="form-group col-lg-12 text-center">
                                        <button className="aButton" type="submit">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Contact;